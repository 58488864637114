// import Validator from 'fastest-validator'

export const pageStartIndex = (curPage, limit) => {
  if (curPage === 1) return 0
  return (curPage * limit) - limit
}

// export const getSchemaFromApi = async () => {
//   const [res, err] = await XXX({})
//   if(err) return
//   const check = v.compile(res.schema)
//   return check
// }
