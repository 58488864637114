<template>
  <div class="faq-block" @click="toggle = !toggle">
    <div class="font-medium flex justify-between items-start" style="gap: 12px">
      <div class="text-gray-100 flex" >
        <p>Q：</p>
        <p class="break-all">{{ data.question }}</p>
      </div>
      <span v-if="!toggle" class="material-icons text-primary-100"> add </span>
      <span v-if="toggle" class="material-icons text-primary-100">
        remove
      </span>
    </div>

    <div v-if="toggle" class="answer markdown-body">
      <div v-html="data.answer" />
    </div>
  </div>
</template>

<script>
import { cleanHtml } from '@/utils/helper'

export default {
  name: 'FAQBlock',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    toggle: false,
  }),
  methods: {
    cleanHtml,
  },
}
</script>

<style lang="postcss" scoped>
.faq-block {
  @apply border border-primary-100 rounded-[10px];
  @apply px-[20px] py-[25px] cursor-pointer;
}
.answer {
  @apply mt-[12px] text-gray-80 px-[35px];
}
</style>
