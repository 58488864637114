<template>
  <main class="faq">
    <!-- <HomeBtn /> -->
    <PageTitle
      title="常見問題"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.go(-1)"
    />

    <div v-loading="loading" class="faq-list">
      <FAQBlock v-for="faq in faqList" :key="faq.id" :data="faq" />
    </div>

    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableOptions.dataCount"
      @pageChange="refresh"
    />
  </main>
</template>

<script>
// import HomeBtn from './HomeBtn.vue'
import FAQBlock from './FAQBlock.vue'
import { GetHelpQA, GetHelpQACount } from '@/api/homePage'
import tableMixin from '@/mixin/table'
import { mapGetters } from 'vuex'

export default {
  name: 'FAQ',
  mixins: [tableMixin],
  components: { FAQBlock,  },
  data: () => ({
    loading: false,
    faqList: [],
  }),
  computed: {
    ...mapGetters(['shop']),
  },
  async mounted() {
    await this.refresh()
  },
  methods: {
    async refresh() {
      this.loading = true
      await this.getHelpQACount()
      await this.getHelpQA()
      this.loading = false
    },
    async getHelpQACount() {
      const [res, err] = await GetHelpQACount({})
      if (err) {
        this.$message.error(err.msg || err)
        return
      }
      this.tableOptions.dataCount = res.count
    },
    async getHelpQA() {
      const [res, err] = await GetHelpQA({
        start: this.pageStartIndex,
        limit: this.tableOptions.pageLimit,
      })
      if (err) {
        this.$message.error(err.msg || err)
        return
      }
      this.faqList = res
    },
  },
}
</script>

<style lang="postcss" scoped>
.faq {
  @apply relative;
}
.faq-list {
  @apply bg-white rounded-[10px] p-[20px];
  @apply flex flex-col gap-[16px];
  box-shadow: 0px 1px 9px rgba(1, 10, 38, 0.1);
}

.question-block {
  @apply border border-primary-100 rounded-[10px];
  @apply px-[20px] py-[25px] cursor-pointer;
}
</style>
